const SvgLoader = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Loader_svg'
    viewBox='0 0 600 600'
    shapeRendering='geometricPrecision'
    textRendering='geometricPrecision'
  >
    <style>
      {
        '#one_tr {animation: one_tr__tr 3000ms linear infinite normal forwards}@keyframes one_tr__tr { 0% {transform: translate(300px,300px) rotate(90deg)} 100% {transform: translate(300px,300px) rotate(810deg)}} #two_tr {animation: two_tr__tr 3000ms linear infinite normal forwards}@keyframes two_tr__tr { 0% {transform: translate(300px,300px) rotate(90deg)} 100% {transform: translate(300px,300px) rotate(810deg)}} #two {animation: two_s_do 3000ms linear infinite normal forwards}@keyframes two_s_do { 0% {stroke-dashoffset: 1602.210000} 100% {stroke-dashoffset: 4806.630000}}'
      }
    </style>
    <g>
      <g id='one_tr' transform='translate(300,300) rotate(90)'>
        <ellipse
          id='one'
          rx='255'
          ry='255'
          transform='scale(-1,1) translate(0,0)'
          fill='none'
          stroke='rgb(122,122,122)'
          strokeWidth='75'
          strokeOpacity='0.2'
          strokeLinecap='round'
        />
      </g>
      <g id='two_tr' transform='translate(300,300) rotate(90)'>
        <ellipse
          id='two'
          rx='255'
          ry='255'
          transform='scale(-1,1) translate(0,0)'
          fill='none'
          stroke='var(--primary-color, #fb6664)'
          strokeWidth='75'
          strokeLinecap='round'
          strokeDashoffset='1602.210000'
          strokeDasharray='1602.210000'
        />
      </g>
    </g>
  </svg>
);
export default SvgLoader;
