/* eslint-disable @typescript-eslint/no-explicit-any */
import { cleanSearchParams, generateRequestURL } from 'helpers/Utils';
import { CompositeAPIReturnType } from 'types';
import {
  Organisation,
  OrganisationAttributePossibleValues,
  OrganisationAttributeType,
  OrganisationConfigPayload,
  OrganisationTerms,
} from 'types/organisations';
import { getData, postData, patchData, compositeData, deleteData, APIListResponse } from 'APIHandler';
import convertCompositeResponseToObject from 'api/convertCompositeResponseToObject';
import { BasicAdminColumnFieldType } from 'components/Table/tableColumnFields';
import { CSVCustomColumns, CSVCustomColumnsById, getCSVColumnsData } from './AdminReportsAPI';

interface CreateOrganisationProps {
  path: string;
  name: string;
  user: {
    email: string;
    name: string;
    phone: string;
  };
  parentOrganisationID?: string; //UUID
}

export const createOrganisation = (payload: CreateOrganisationProps, bypassAuth?: boolean) => {
  return postData('AppAPI', 'organisation', payload, bypassAuth ? { bypassAuth } : undefined);
};

export interface UnAuthenticatedLoginParametersResAuth {
  identityPoolId: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}

export interface UnAuthenticatedLoginParametersResBranding {
  lightModeLogoURL: string;
  lightModeFaviconURL: string;
  lightModePrimaryColour: string;
  logoType: string;
  textLogo: string;
  textLogoColour: string;
}

export interface UnAuthenticatedLoginParametersRes {
  auth: UnAuthenticatedLoginParametersResAuth;
  organisationName: string;
  active: boolean;
  branding: UnAuthenticatedLoginParametersResBranding;
}

export const searchForOrganisation = async (slug: string) => {
  try {
    const response = await getData('AppAPI', `organisation/${slug}/loginparameters`, { bypassAuth: true });
    if (response) return response;

    return null;
  } catch (error) {
    if (error.status === 404) {
      return null;
    }
    return error;
  }
};

interface OrganisationsQueryParams {
  limit?: number;
  offset?: number;
  isEnabled?: boolean;
  isMarkedForDeletion?: boolean;
  search?: string;
  includeChildren?: boolean;
  // CSV settings
  format?: 'csv';
  csvFileName?: string;
  columns?: string;
}
export const getOrganisations = (params?: OrganisationsQueryParams) => {
  const queryString = cleanSearchParams(new URLSearchParams(params as any));

  return getData('OrganisationAPI', `organisations?${queryString}`);
};

export const getChildOrganisations = (orgId: string, params?: OrganisationsQueryParams) => {
  const queryString = cleanSearchParams(new URLSearchParams(params as any));

  return getData('AppAPI', `organisation/${orgId}/organisations?${queryString}`);
};

export const getParentAndChildOrganisations = async (orgId: string, params?: OrganisationsQueryParams) => {
  const queryString = cleanSearchParams(new URLSearchParams(params as any));

  const orgs = await compositeData('CompositeOrganisationAPI', [
    {
      method: 'GET',
      url: `/organisation/${orgId}`,
      referenceId: 'parentOrg',
    },
    {
      method: 'GET',
      url: `/organisation/${orgId}/organisations?${queryString}`,
      referenceId: 'childOrgs',
    },
  ]);

  return convertCompositeResponseToObject(orgs);
};

export const getOrganisation = (orgId: string) => {
  return getData('AppAPI', `organisation/${orgId}`);
};

export const updateOrganisationConfig = async (payload: OrganisationConfigPayload) => patchData('OrganisationAPI', `configuration`, payload);

export const remapAttributes = (attributes: OrganisationAttributeType[]) => {
  const attributesObject: { [key: string]: OrganisationAttributeType } = {};
  attributes.forEach((item: OrganisationAttributeType) => {
    attributesObject[item.key] = item;
  });

  return attributesObject;
};

export const loadOrganisationConfigAndAttributes = () => {
  const orgConfigAndAttributes = compositeData('CompositeOrganisationAPI', [
    // Config data
    {
      method: 'GET',
      url: `/organisation/*`,
      referenceId: `config`,
    },
    // Attributes data
    {
      method: 'GET',
      url: `/organisation/*/attribute`,
      referenceId: `attributes`,
    },
  ])
    .then((res) => {
      const { responses } = res;

      const config = responses.find((item: CompositeAPIReturnType) => item.referenceID === 'config');
      const attributes = responses.find((item: CompositeAPIReturnType) => item.referenceID === 'attributes');

      const remappedAttributes = remapAttributes(attributes.body.items);

      return {
        config: config.body,
        attributes: remappedAttributes,
      };
    })
    .catch((err) => err);

  return orgConfigAndAttributes;
};

export const getOrganisationAttributes = () => getData('OrganisationAPI', 'attributes');

export const loadOrganisationAttributes = () => {
  const attributes = getData('OrganisationAPI', 'attribute')
    .then((res) => {
      return remapAttributes(res.items);
    })
    .catch((err) => err);

  return attributes;
};

export const loadSuperAdminOrganisationAttributes = (orgId: string) => getData('AppAPI', `organisation/${orgId}/attributes`);

// Helper function that returns attributes as a value/label pair. If showId is true, it will assign the uuid as the value instead of the value
export const createAttributeOptions = (possibleValues: OrganisationAttributePossibleValues[], showId?: boolean) => {
  return possibleValues.map((attr) => ({ value: showId ? attr.id : attr.value, label: attr.value }));
};

//////

interface OrgTermsQueryParams {
  limit?: number;
  offset?: number;
}

export interface OrgTermsResponse {
  body: string;
  version: number;
  createdAt: string;
}

// getOrgTerms (just the terms)
export const getOrganisationTerms = (params?: OrgTermsQueryParams) => {
  return getData('OrganisationAPI', generateRequestURL('termsconditions', params));
};

// getOrgTerms and config data (composite)
export const getOrganisationTermsAndConfig = (
  params?: OrgTermsQueryParams,
): Promise<{ terms: APIListResponse<OrganisationTerms>; config: Organisation }> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const queryString = new URLSearchParams(params as any);

  return compositeData('CompositeOrganisationAPI', [
    {
      method: 'GET',
      url: `/organisation/*/termsconditions?${queryString}`,
      referenceId: 'getOrgTerms',
    },
    {
      method: 'GET',
      url: `/organisation/*`,
      referenceId: 'getOrgConfig',
    },
  ]).then((res) => {
    const { responses } = res;

    const terms = responses.find((item: CompositeAPIReturnType) => item.referenceID === 'getOrgTerms');
    const config = responses.find((item: CompositeAPIReturnType) => item.referenceID === 'getOrgConfig');

    return {
      terms: terms.body,
      config: config.body,
    };
  });
};

// Update the org terms
export const updateOrganisationTerms = (body: string) => {
  return postData('OrganisationAPI', 'termsconditions', { body });
};

// Update whether org terms are enabled or not
export const updateOrganisationTermsActiveState = (arePoliciesEnabled: boolean) => {
  return patchData('OrganisationAPI', 'configuration', { arePoliciesEnabled });
};

// Composite request for updating terms and active state
export const updateOrganisationTermsAndActiveState = (body: string, arePoliciesEnabled: boolean) => {
  return compositeData('CompositeOrganisationAPI', [
    {
      method: 'POST',
      url: `/organisation/*/termsconditions`,
      referenceId: 'updateTermsBody',
      body: { body },
    },
    {
      method: 'PATCH',
      url: `/organisation/*/configuration`,
      referenceId: 'updateTermsActive',
      body: { arePoliciesEnabled },
    },
  ]);
};

// Super Admin/Parent admin
export const updateOrganisation = (orgPath: string, payload: Partial<Organisation>) => {
  return patchData('AppAPI', `organisation/${orgPath}/configuration`, payload);
};

export const getOrgPresign = (organisation: string) => {
  return getData('AppAPI', `misc/${organisation}/readpresign`);
};

export const deleteOrganisation = (orgPath: string) => {
  return deleteData('AppAPI', `organisation/${orgPath}`);
};

export const deleteOrganisations = (orgPaths: string[]) => {
  return compositeData(
    'CompositeOrganisationAPI',
    orgPaths.map((orgPath, i) => {
      return {
        method: 'DELETE',
        url: `/organisation/${orgPath}`,
        referenceId: `deleteOrg${i}`,
      };
    }),
  );
};

export const restoreOrganisation = (orgPath: string) => {
  return postData('AppAPI', `organisation/${orgPath}/restore`);
};

// CSV Functions
export type SuperAdminOrganisationsCSVCustomColumnValues =
  | 'organisation.id'
  | 'organisation.legacyID'
  | 'organisation.path'
  | 'organisation.name'
  | 'organisation.createdAt'
  | 'organisation.updatedAt'
  | 'organisation.deletedAt'
  | 'organisation.isEnabled'
  | 'organisation.licenseLimit'
  | 'organisation.arePoliciesEnabled'
  | 'organisation.parentOrganisationID'
  | 'organisation.status'
  | 'organisation.childOrganisations'
  | `featureflag.${string}`;

export type SuperAdminOrganisationsCSVCustomColumns = CSVCustomColumns<SuperAdminOrganisationsCSVCustomColumnValues>;
export type SuperAdminOrganisationsCSVCustomColumnsById = CSVCustomColumnsById<SuperAdminOrganisationsCSVCustomColumnValues>;

export const getSuperAdminOrganisationsColumns = (
  columnFields: BasicAdminColumnFieldType[],
  csvData: SuperAdminOrganisationsCSVCustomColumnsById[],
): SuperAdminOrganisationsCSVCustomColumns[] => {
  return getCSVColumnsData<SuperAdminOrganisationsCSVCustomColumns, SuperAdminOrganisationsCSVCustomColumnsById>(columnFields, csvData);
};

/*** NEW ORG ATTRIBUTE ENDPOINTS ***/

export const createOrgAttribute = (data: OrganisationAttributeType) => {
  const formattedAttributeData = JSON.parse(JSON.stringify(data));
  if (formattedAttributeData.type !== 'pre-populated') delete formattedAttributeData.possibleValues;
  return postData('OrganisationAPI', 'attribute', formattedAttributeData);
};

export const updateOrgAttribute = (data: OrganisationAttributeType) =>
  patchData('OrganisationAPI', `attributes/${data.key}`, { label: data.label, possibleValues: data.possibleValues });

export const deleteOrgAttribute = (key: string) => deleteData('OrganisationAPI', `attributes/${key}`);
