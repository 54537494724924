import { APIName, getEndpoint } from 'api/APIEndpoints';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { CompositeAPIRequestType, CompositeAPIReturnType } from 'types';

interface APIRequestOptions {
  bypassAuth?: boolean;
  returnJSONError?: boolean;
}

const getJSON = async (response: Response) => {
  const contentLength = response.headers.get('Content-Length');
  const contentType = response.headers.get('Content-Type');

  if (contentType !== 'application/json') return response;
  // handle successful responses that have no content
  // as they cause response.json() to error
  if (response.status === 204 || contentLength === '0') return null;

  return response.json();
};

export const getOrganisationID = (): string => {
  const parts = window.location.pathname.split('/');

  if (parts.length > 1 && parts[1] !== '') {
    return parts[1];
  }

  return '';
};

const fetchEndpoint = async (endpoint: URL | RequestInfo, init: RequestInit, returnJSONError?: boolean) => {
  const response = await fetch(endpoint, init);

  if (!response.ok) {
    const contentLength = response.headers.get('Content-Length');
    const contentType = response.headers.get('Content-Type');

    if (contentType === 'application/json' && contentLength !== '0' && returnJSONError) {
      return Promise.reject(await response.json());
    } else {
      return Promise.reject(response);
    }
  }

  return getJSON(response);
};

export const handleRefreshTimeOut = () => {
  const organisationId = getOrganisationID();

  if (organisationId !== '') {
    Auth.signOut();
    const location = `/${organisationId}/hub`;
    window.location.assign(location);
  }
};

const retryAttempts = 5;

const wait = (ms: number): Promise<number> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(ms);
    }, ms);
  });
};

const getExponentialBackoff = (attempts: number): number => {
  let localAttempts = attempts;
  const timeout = Math.max((localAttempts *= 2), 1) * 1000;
  return timeout;
};

const getInit = async (
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  requestBody?: object | string,
  bypassAuth = false,
): Promise<RequestInit> => {
  const headers = new Headers();
  let body;

  if (!bypassAuth) {
    headers.append('Authorization', `${(await Auth.currentSession()).getIdToken().getJwtToken()}`);
  }

  if (requestBody) {
    body = typeof requestBody === 'string' ? requestBody : JSON.stringify(requestBody);

    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Content-Length', body.length.toString());
  }

  return { method, headers, body };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleError = (e: any, apiName: string, path: string, endpoint: string) => {
  if (e.message === 'Refresh Token has expired') {
    handleRefreshTimeOut();
    return false;
  }

  if (e === 'No current user') {
    handleRefreshTimeOut();
    return false;
  }

  if (e.message === 'Network Error') {
    return 'Network Error';
  }

  Sentry.withScope((scope) => {
    scope.setTag('api-name', apiName);
    scope.setTag('path', path);
    scope.setTag('endpoint', endpoint);
    scope.setTag('type', 'apifailure');
    scope.setLevel('fatal');
    scope.setFingerprint([apiName, path, e.message, 'type:apifailure']);
    Sentry.captureException(e);
  });

  if (e.response && e.response.status > 500) {
    return false;
  }

  throw e;
};

const get = async (apiName: APIName, path: string, opts: APIRequestOptions = {}) => {
  const endpoint = getEndpoint(apiName, path);
  try {
    const init = await getInit('GET', undefined, opts.bypassAuth);
    return await fetchEndpoint(endpoint, init);
  } catch (e) {
    return handleError(e, apiName, path, endpoint);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getData = async (apiName: APIName, path: string, opts: APIRequestOptions = {}, attempts = 0): Promise<any> => {
  let localAttempts = attempts;

  const response = await get(apiName, path, opts);

  if (!response && localAttempts < retryAttempts) {
    localAttempts += 1;
    await wait(getExponentialBackoff(localAttempts));
    return getData(apiName, path, opts, localAttempts);
  }

  return response;
};

export const postData = async (apiName: APIName, path: string, message?: object | string, opts: APIRequestOptions = {}) => {
  const endpoint = getEndpoint(apiName, path);
  try {
    const init = await getInit('POST', message, opts.bypassAuth);
    return await fetchEndpoint(endpoint, init, opts.returnJSONError);
  } catch (e) {
    return handleError(e, apiName, path, endpoint);
  }
};

export const compositeData = async (apiName: APIName, requests: CompositeAPIRequestType[], errorOnSingularFailure = false) => {
  const orgID = getOrganisationID();
  const compositeRequests = requests.map((req) => {
    const endpoint = req.url.replace('*', orgID);
    return {
      method: req.method,
      // Pass * to url to represent orgID
      url: endpoint,
      referenceId: req.referenceId,
      body: req.body,
    };
  });

  const data = await postData(apiName, '', {
    compositeRequest: compositeRequests,
  }).catch((e) => {
    Sentry.withScope((scope) => {
      scope.setTag('api-name', apiName);
      scope.setTag('type', 'apifailure');
      scope.setLevel('fatal');
      scope.setFingerprint([apiName, 'type:apifailure']);
      Sentry.captureException(e);
    });
  });

  if (!errorOnSingularFailure) return data;

  // Option 1 - only return data if all sub requests are successful
  // Check if 'responses' object has data and that every sub request is successful, otherwise throw an error
  if (data.responses && data.responses.every((res: CompositeAPIReturnType) => res.status < 400)) {
    return data;
  } else {
    Sentry.withScope((scope) => {
      scope.setTag('api-name', apiName);
      scope.setTag('type', 'apifailure');
      scope.setLevel('fatal');
      scope.setFingerprint([apiName, 'type:apifailure']);
      Sentry.captureException(new Error('One of the composite sub requests has failed'));
    });
    throw new Error('One of the composite sub requests has failed');
  }

  // Option 2 - always return the data but send a console error for all failed composite requests
  // if (data.responses) {
  //   const errorLog: { [key: string]: CompositeAPIReturnType } = {};
  //   data.responses.forEach((res: CompositeAPIReturnType) => {
  //     if (res.status >= 400) {
  //       errorLog[res.referenceID] = res;
  //     }
  //   });

  //   if (Object.keys(errorLog).length > 0) {
  //     console.error('Failed composite requests:', errorLog);
  //   }
  // }

  // return data;
};

export const putData = async (apiName: APIName, path: string, message?: object | string) => {
  const endpoint = getEndpoint(apiName, path);

  try {
    const init = await getInit('PUT', message);
    return await fetchEndpoint(endpoint, init);
  } catch (e) {
    return handleError(e, apiName, path, endpoint);
  }
};

export const patchData = async (apiName: APIName, path: string, message?: object | string) => {
  const endpoint = getEndpoint(apiName, path);

  try {
    const init = await getInit('PATCH', message);
    return await fetchEndpoint(endpoint, init);
  } catch (e) {
    return handleError(e, apiName, path, endpoint);
  }
};

export const deleteData = async (apiName: APIName, path: string, message?: object | string) => {
  const endpoint = getEndpoint(apiName, path);

  try {
    const init = await getInit('DELETE', message);

    return await fetchEndpoint(endpoint, init);
  } catch (e) {
    return handleError(e, apiName, path, endpoint);
  }
};

export type APIListResponse<T = unknown> = { items: T[]; itemCount: number };
