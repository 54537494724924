import { createContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import baseTheme from 'theming/baseTheme';

export const ThemeContext = createContext({ theme: 'light', switchTheme: () => {}, toggleTheme: () => {} } as {
  // Some nasty types here due to localstorage
  theme: string | 'light' | 'dark';
  switchTheme: (val: 'light' | 'dark') => void;
  toggleTheme: () => void;
});

interface ThemeHandlerProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

const ThemeHandler = ({ children, isAuthenticated }: ThemeHandlerProps) => {
  const [theme, setTheme] = useState('light');
  const dynamicTheme = { ...baseTheme, mode: theme };
  const switchTheme = (newTheme: 'dark' | 'light') => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };
  const toggleTheme = () => {
    switchTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    setTheme(isAuthenticated ? localStorage.getItem('theme') || 'light' : 'light');
  }, [isAuthenticated]);

  return (
    <ThemeContext.Provider value={{ theme, switchTheme, toggleTheme }}>
      <ThemeProvider theme={dynamicTheme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeHandler;
