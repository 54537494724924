const getUsersLocale = () => {
  let locale = localStorage.getItem('HulerWebsite_Locale');

  if (locale === null) {
    localStorage.setItem('HulerWebsite_Locale', 'en-GB');
    locale = 'en-GB';
  }

  return locale;
};

export default getUsersLocale;
