import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Suspense, lazy, useEffect } from 'react';
import { platform } from '@todesktop/client-core';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useAuth } from 'contexts/AuthContext';
import { OrgContextProvider } from 'contexts/OrgContext';
import CookieContextProvider from 'contexts/CookieContext';
import ThemeWrapper from 'components/ThemeWrapper';
import GlobalStyles from 'theming/GlobalStyles/GlobalStyles';
import MaintenancePage from 'components/MaintenancePage/MaintenancePage';
import { DesktopAppHeader } from 'components/DesktopApp/DesktopAppHeader';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import Loader from 'components/UI/Loader/Loader';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';
import { CookieAcceptanceData } from 'helpers/Utils';

const UnAuthenticatedApp = lazy(() => import('./UnAuthenticatedApp'));
const AuthenticatedApp = lazy(/* webpackPrefetch: true */ () => import('./AuthenticatedApp'));

const App = () => {
  const { user } = useAuth();

  // Let's give this user a simple id that we can identify them by
  useEffect(() => {
    const generateFingerprint = async () => {
      const fpAgent = await FingerprintJS.load();
      const result = await fpAgent.get();
      const { visitorId } = result;
      return visitorId;
    };

    const checkBrowserIdentity = async () => {
      if (localStorage) {
        let browserFingerprint = localStorage.getItem('@browser-fingerprint');
        if (!browserFingerprint) {
          browserFingerprint = await generateFingerprint();
          localStorage.setItem('@browser-fingerprint', browserFingerprint);
        }
      }
    };

    checkBrowserIdentity();
  }, []);

  const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 1 } } });

  const postHogOptions: Partial<PostHogConfig> = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    persistence: localStorage.getItem(`${CookieAcceptanceData.appName}_performance`) ? 'localStorage+cookie' : 'memory',
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '[data-redacted]',
    },
    capture_pageview: false,
  };

  return (
    <Suspense fallback={<Loader fullscreen />}>
      {/* https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default */}
      <StyleSheetManager
        shouldForwardProp={(propName, target) => {
          if (typeof target === 'string') {
            // For HTML elements, forward the prop if it is a valid HTML attribute
            return isPropValid(propName);
          }
          // For other elements, forward all props
          return true;
        }}
      >
        <ThemeWrapper isAuthenticated={user !== null}>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            {process.env.REACT_APP_MAINTENANCE_MODE === 'true' ? (
              <MaintenancePage />
            ) : (
              <CookieContextProvider>
                <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
                  {platform.todesktop.isDesktopApp() ? <DesktopAppHeader /> : ''}
                  {user ? (
                    <OrgContextProvider>
                      <AuthenticatedApp />
                    </OrgContextProvider>
                  ) : (
                    <UnAuthenticatedApp />
                  )}
                </PostHogProvider>
              </CookieContextProvider>
            )}
          </QueryClientProvider>
        </ThemeWrapper>
      </StyleSheetManager>
    </Suspense>
  );
};

export default App;
