import styled from 'styled-components';

export const DesktopAppHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  -webkit-app-region: drag;
  background-color: var(--background);
  border-bottom: 1px solid var(--border);
  // Must be highest z-index to be able to drag the window
  z-index: 999999999999999999999999999;
`;
