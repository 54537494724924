import { createContext, useEffect, useCallback, useMemo, useContext, ReactNode } from 'react';
import Loader from 'components/UI/Loader/Loader';
import { useAsync } from 'hooks/useAsyncHook';
import { ConfigReturnType } from 'types/config';
import bootstrapConfig from '../api/bootstrap-api';

async function bootstrapConfigData() {
  try {
    const config = await bootstrapConfig();
    return config;
  } catch (error) {
    return error;
  }
}

interface ConfigContextInterface {
  config: ConfigReturnType;
  setConfig: (orgId: string) => Promise<void>;
}

const defaultValue = {
  config: {
    SSO: undefined,
    Active: false,
    organisationId: '',
  },
  setConfig: () => Promise.resolve(),
};

const ConfigContext = createContext<ConfigContextInterface>(defaultValue);

interface ConfigProviderProps {
  children?: ReactNode;
}

function ConfigProvider({ children }: ConfigProviderProps) {
  const { data: config, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();

  useEffect(() => {
    const configDataPromise = bootstrapConfigData();
    run(configDataPromise);
  }, [run]);

  const setConfig = useCallback(
    async (orgId: string) => {
      const orgConfig = await bootstrapConfig(orgId);
      setData(orgConfig);
    },
    [setData],
  );

  const value = useMemo(() => ({ config, setConfig }), [config, setConfig]);

  if (isLoading || isIdle) {
    return <Loader fullscreen />;
  }

  if (isError) {
    return <div>Error Occurred {error?.message} </div>;
  }

  if (isSuccess) {
    return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(`useConfig must be used within a Config Provider`);
  }

  return context;
}

export { ConfigProvider, useConfig };
