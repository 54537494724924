import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import AppProviders from './contexts';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : process.env.NODE_ENV,
  release: 'huler-web@' + process.env.REACT_APP_VERSION,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [Sentry.captureConsoleIntegration()],
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // If commenting out/re-enabling Strict Mode, remember to do a final check below for stray commas/comment marks
  <StrictMode>
    <Sentry.ErrorBoundary fallback={Error}>
      <AppProviders>
        <App />
      </AppProviders>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);

const config = {
  // When our app is updated, a new service worker will become avaialble to existing instances of the app
  // By default, browsers will wait until all instances are closed before updating the service worker
  // This code ensures that when a new service worker is waiting...
  // ... We immediately update and force a refresh of the browser
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

serviceWorkerRegistration.register(config);
