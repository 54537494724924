import styled from 'styled-components';
import { header60, header65, paragraph60 } from 'theming/typography';

interface MaintenancePageProps {
  className?: string;
}

const MaintenancePage = ({ className }: MaintenancePageProps) => {
  return (
    <div className={className}>
      <div>
        <img alt='logo' src='https://cdn.huler.io/huler-hub/huler-maintenance-icon.png' />
        <h1>
          Huler is down for a bit of maintenance<span>.</span>
        </h1>
        <p>Don’t worry, we’ll be right back!</p>
      </div>
    </div>
  );
};

const StyledMaintenancePage = styled(MaintenancePage)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background);
  background-image: url(${(props) =>
    props.theme.mode === 'light'
      ? 'https://cdn.huler.io/huler-hub/huler-maintenance-light.jpg'
      : 'https://cdn.huler.io/huler-hub/huler-maintenance-dark.jpg'});
  background-size: cover;
  background-position: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: ${(props) => props.theme.zLayers.above};

    img {
      height: 95px;
      width: 95px;
      object-fit: contain;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    h1 {
      ${header65}
      max-width: 400px;
      text-align: center;
      margin-bottom: 23px;

      span {
        color: var(--primary-color);
      }
    }

    p {
      ${header60}
      font-weight: var(--sofia-light);
      text-align: center;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.medium}) {
    > div {
      padding: 0 20px;
      > img {
        width: 60px;
        height: 60px;
        margin-bottom: 20px;
      }
      h1 {
        ${header60}
        max-width: 300px;
      }
      p {
        ${paragraph60}
        font-weight: var(--sofia-light);
      }
    }
  }
`;

export default StyledMaintenancePage;
