import { createContext, ReactNode, useState } from 'react';

export type CookieValues = 'performance' | 'functional';

interface CookieContextValueType {
  performanceCookiesActive: boolean;
  functionalCookiesActive: boolean;
  injectScript: (cookie: CookieValues) => void;
  cookieSettingsActive: boolean;
  setCookieSettingsActive: (val: boolean) => void;
  cookieList: string[];
}

export const CookieContext = createContext<CookieContextValueType>({
  performanceCookiesActive: false,
  functionalCookiesActive: false,
  injectScript: (cookie: CookieValues) => cookie,
  setCookieSettingsActive: (val: boolean) => val,
  cookieSettingsActive: false,
  cookieList: [],
});

interface CookieContextProviderProps {
  children: ReactNode;
}

const CookieContextProvider = ({ children }: CookieContextProviderProps) => {
  const [performanceCookiesActive, setPerformanceCookiesActive] = useState(false);
  const [functionalCookiesActive, setFunctionalCookiesActive] = useState(false);
  const [cookieSettingsActive, setCookieSettingsActive] = useState(false);

  const cookieList = ['performance', 'functional'];

  const injectScript = (cookie: CookieValues) => {
    switch (cookie) {
      case 'performance':
        setPerformanceCookiesActive(true);
        break;
      case 'functional':
        setFunctionalCookiesActive(true);
        break;
      default:
        break;
    }
  };

  return (
    <CookieContext.Provider
      value={{ performanceCookiesActive, functionalCookiesActive, injectScript, cookieSettingsActive, setCookieSettingsActive, cookieList }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export default CookieContextProvider;
