import { css } from 'styled-components';

const font80 = css`
  font-size: 80px;
  line-height: 88px;
  letter-spacing: -1.72px;
`;

const font54 = css`
  font-size: 54px;
  line-height: 65px;
  letter-spacing: -0.54px;
`;

const font40 = css`
  font-size: 40px;
  line-height: 45px;
  letter-spacing: 0px;
`;

const font30 = css`
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0px;
`;

const font24 = css`
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0px;
`;

const font20 = css`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
`;

const font18 = css`
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0px;
`;

const font16 = css`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0px;
`;

const font14 = css`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
`;

const font12 = css`
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
`;

const font11 = css`
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0px;
`;

const font10 = css`
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0px;
`;

const display10 = css`
  ${font80}
  font-weight: var(--sofia-black);
`;

const header70 = css`
  ${font54}
  font-weight: var(--sofia-bold);
`;

const header65 = css`
  ${font40}
  font-weight: var(--sofia-bold);
`;

const header60 = css`
  ${font30}
  font-weight: var(--sofia-bold);
`;

const header50 = css`
  ${font24}
  font-weight: var(--sofia-bold);
`;

const header40 = css`
  ${font20}
  font-weight: var(--sofia-bold);
`;

const paragraph60 = css`
  ${font20}
  font-weight: var(--sofia-light);
`;

const header30 = css`
  ${font18}
  font-weight: var(--sofia-bold);
`;

const paragraph50 = css`
  ${font18}
  font-weight: var(--sofia-light);
`;

const header20 = css`
  ${font16}
  font-weight: var(--sofia-bold);
`;

const paragraph45 = css`
  ${font16}
  font-weight: var(--sofia-regular);
`;

const paragraph40 = css`
  ${font16}
  font-weight: var(--sofia-light);
`;

const header10 = css`
  ${font14}
  font-weight: var(--sofia-bold);
`;

const paragraph35 = css`
  ${font14}
  font-weight: var(--sofia-medium);
`;

const paragraph30 = css`
  ${font14}
  font-weight: var(--sofia-light);
`;

const link20 = css`
  ${font12}
  font-weight: var(--sofia-semibold);
`;

const emphasis10 = css`
  ${font12}
  font-weight: var(--sofia-bold);
`;

const paragraph20 = css`
  ${font12}
  font-weight: var(--sofia-medium);
`;

const paragraph10 = css`
  ${font12}
  font-weight: var(--sofia-light);
`;

const caption30 = css`
  ${font11}
  font-weight: var(--sofia-bold);
`;

const link10 = css`
  ${font11}
  font-weight: var(--sofia-light);
`;

const caption20 = css`
  ${font10}
  font-weight: var(--sofia-semibold);
`;

const caption10 = css`
  ${font10}
  font-weight: var(--sofia-medium);
`;

export {
  display10,
  header70,
  header65,
  header60,
  header50,
  header40,
  paragraph60,
  header30,
  paragraph50,
  header20,
  paragraph45,
  paragraph40,
  header10,
  paragraph35,
  paragraph30,
  link20,
  emphasis10,
  paragraph20,
  paragraph10,
  caption30,
  link10,
  caption20,
  caption10,
};
