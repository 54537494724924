import { useRef, useLayoutEffect, useCallback, useReducer } from 'react';

interface ErrorType {
  code: string;
  message?: string;
}
interface StateType {
  status: 'idle' | 'resolved' | 'rejected' | 'pending';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  error?: ErrorType | null;
}

function useSafeDispatch(dispatch: (val: Partial<StateType>) => void) {
  const mounted = useRef(false);
  useLayoutEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  return useCallback((args: Partial<StateType>) => (mounted.current ? dispatch(args) : void 0), [dispatch]);
}

const defaultInitialState: StateType = { status: 'idle', data: null, error: null };

export function useAsync() {
  const initialStateRef = useRef<StateType>(defaultInitialState);
  const [{ status, data, error }, setState] = useReducer((state: StateType, action: Partial<StateType>) => {
    return { ...state, ...action };
  }, initialStateRef.current);

  const safeSetState = useSafeDispatch(setState);

  const setData = useCallback((newData: unknown) => safeSetState({ data: newData, status: 'resolved' }), [safeSetState]);
  const setError = useCallback((newError?: ErrorType | null) => safeSetState({ error: newError, status: 'rejected' }), [safeSetState]);
  const reset = useCallback(() => safeSetState(initialStateRef.current), [safeSetState]);

  const run = useCallback(
    (promise: Promise<unknown>) => {
      if (!promise || !promise.then) {
        throw new Error('The argument passed to useAsync().run must be a promise');
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (newData: any) => {
          setData(newData);
          return newData;
        },
        (newError: ErrorType) => {
          setError(newError);
          return Promise.reject(newError);
        },
      );
    },
    [safeSetState, setData, setError],
  );

  return {
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    setData,
    setError,
    error,
    status,
    data,
    run,
    reset,
  };
}

export default { useAsync };
