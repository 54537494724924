import { css } from 'styled-components';
import { scrollStyling } from 'theming/mixins';
import warningImg from 'assets/img/warning.svg';

const forms = css`
  .form {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    &__group {
      width: 48%;
      position: relative;
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      &:not(&--fw):nth-child(2n + 1) {
        margin-right: 4%;
      }
      &--fw {
        width: 100%;
        margin-right: 0 !important;
      }
      &--error {
        input:not([type='submit']),
        textarea,
        select {
          border-bottom-color: var(--primary-color) !important;
        }
        input,
        .reactSelect__control {
          border: 1px solid var(--primary-color) !important;
          background-image: url(${warningImg}) !important;
          background-size: 16px !important;
          background-position: 98% center !important;
          background-repeat: no-repeat !important;
        }
      }
      @media (max-width: ${(props) => props.theme.breakpoints.small}) {
        width: 100%;
        margin: 5px 0;
      }
      input:not([type='submit']),
      textarea,
      select {
        width: 100%;
        background: none;
        border: none;
        font-weight: 100;
        font-size: 16px;
        border: 1px solid var(--border);
        padding: 10px;
        outline: none;
        color: var(--body-text-color);
        font-family: ${(props) => props.theme.fonts.primaryFont};
        box-sizing: border-box;
        border-radius: 0;
        &::placeholder {
          font-size: 16px;
          color: var(--body-text-color);
        }
        &:hover,
        &:focus {
          border: 1px solid var(--primary-color);
        }
        &.form__input--limit {
          padding-right: 120px;
        }
        &.form__input--m {
          font-size: 24px;
          font-weight: 600;
          padding: 15px 0;
          color: var(--title-color);
          &::placeholder {
            font-size: 24px;
            color: var(--title-color);
          }
          @media (max-width: ${(props) => props.theme.breakpoints.small}) {
            font-size: 16px;
            &::placeholder {
              font-size: 16px;
            }
          }
        }
        &:read-only {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }
      textarea {
        resize: none;
        min-height: 150px;
        &.large {
          min-height: 300px;
        }
      }
      label {
        width: 100%;
        margin-bottom: 11px;
        font-weight: 600;
        font-size: 14px;
        display: block;
        color: var(--body-text-color);
        opacity: 0.5;
        small {
          display: block;
          font-weight: normal;
          margin-top: 0.5em;
          font-size: 12px;
          opacity: 0.8;
        }
      }
      &__description {
        height: 50px;
        font-size: 12px;
        margin: 0 0 10px 0 !important;
        overflow: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
          -webkit-appearance: none;
        }
      }
    }
    &__error {
      background-color: var(--primary-color);
      margin: 0 !important;

      padding: 7px 10px 11px 10px;
      border-radius: 0 0 10px 10px;
      width: 100%;
      display: block;
      color: #fff;
      position: relative;
      z-index: ${(props) => props.theme.zLayers.overlay};
    }

    &__input-container {
      position: relative;
      width: 100%;
      margin: 0 0 30px 0;
    }

    h2 {
      margin-bottom: 23px;
      width: 100%;
    }
    h3 {
      margin-bottom: 10px;
      width: 100%;
    }
    h4 {
      margin-bottom: 10px;
      width: 100%;
      border-bottom: 1px solid var(--border);
      padding: 10px 0;
      margin: 0 0 20px 0 !important;
      display: block;
    }
    p {
      font-size: 16px;
      margin-bottom: 23px;
    }
  }
  .reactSelect {
    text-align: left;
    width: 100%;
    .reactSelect__control {
      * {
        font-family: inherit;
      }
      width: 100%;
      background-color: transparent;
      border: 1px solid var(--border);
      border-radius: 0;
      padding: 6px 5px;
      box-shadow: none !important;
      &:hover,
      &:focus {
        border: 1px solid var(--primary-color);
      }
      &--menu-is-open {
        .react-select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }
    .reactSelect__value-container {
      padding: 0 2px;
      line-height: 200%;
      &:not(.react-select__value-container--has-value) {
        cursor: pointer;
      }
    }
    .reactSelect__input {
      padding-left: 10px;
    }
    .reactSelect__multi-value {
      background: black;
      color: #fff;
      border: none;
      border-radius: 100px;
      padding: 0 3px 0 8px;
      font-size: 14px;
      &__label {
        color: inherit;
        top: -1px;
        position: relative;
        padding: 5px 2px;
        font-size: 12px;
      }
      &__remove {
        cursor: pointer;
        &:hover {
          background-color: initial;
          color: var(--primary-color);
        }
      }
    }

    .reactSelect__single-value {
      color: var(--body-text-color);
      border: none;
      border-radius: 100px;
      padding: 0 3px 0 8px;
      font-size: 14px;
    }
    .reactSelect__clear-indicator,
    .reactSelect__indicator-separator {
      display: none;
    }
    .reactSelect__dropdown-indicator {
      padding: 3px 6px 3px 6px;
      margin-right: 6px;
      transition: all 0.2s ease-in-out;
      color: var(--body-text-color);
      svg {
        fill: currentColor !important;
        width: 12px;
        height: 12px;
      }
    }
    .reactSelect__placeholder {
      font-size: 12px;
      padding-left: 10px;
      color: inherit;
    }
    .reactSelect__menu {
      background-color: var(--select-options-background);
    }
    .reactSelect__menu-list {
      max-height: 130px;
      overflow: auto;
      ${scrollStyling}
    }
    .reactSelect__option {
      background-color: var(--select-options-option-background);
      border: none !important;
      padding: 10px 15px;
      cursor: pointer;
      color: var(--title-color);
      &:hover {
        background-color: var(--selected-option-background);
      }
    }
    .reactSelect__option--is-selected {
      background-color: var(--selected-option-background);
    }
    .reactSelect--curved {
      max-width: 400px;

      > .reactSelect__control {
        border-radius: 5px !important;
        font-weight: bold;

        .reactSelect__single-value {
          line-height: 150%;
        }
      }
    }
  }
`;

export default forms;
