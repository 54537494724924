import { APIListResponse } from 'APIHandler';
import { AudienceCoreType } from './audiences';
import { UserCoreType } from './users';

export type TileTypes =
  | ''
  | 'KUDOS'
  | 'VIDEO'
  | 'BOOKMARK'
  | 'ARTICLE'
  | 'XERO'
  | 'COLLECTION'
  | 'UPLOAD'
  | 'IMAGE_BANK'
  | 'LEARN'
  | 'JOURNALS'
  | 'BOXSET'
  | 'SEASON'
  | 'SCORM'
  | 'UPLOADED_VIDEO';

export const tileTypes: TileTypes[] = [
  'KUDOS',
  'VIDEO',
  'BOOKMARK',
  'ARTICLE',
  'XERO',
  'COLLECTION',
  'UPLOAD',
  'IMAGE_BANK',
  'LEARN',
  'BOXSET',
  'SEASON',
  'SCORM',
  'UPLOADED_VIDEO',
  'JOURNALS',
];

// Tile properties that are editable
export type EditableTileType = Exclude<
  TileType,
  | 'id'
  | 'legacyID'
  | 'organisationID'
  | 'createdBy'
  | 'createdAt'
  | 'updatedAt'
  | 'deletedAt'
  | 'type'
  | 'isEnabled'
  | 'isManagedByOrganisation'
  | 'isAllowedExternally'
  | 'isHiddenFromOrganisation'
  | 'userRoles'
>;

export interface TileUserData {
  progress: number | null; // From 0-1 e.g 0, 0.5, 1 (BE send null if mandatory content has not been started)
  isCompleted: boolean;
  isPassed: boolean;
  reaction: 'LIKE' | null;
}

export interface TileCookie {
  domain: string;
  expires: string;
  name: string;
  path: string;
  value: string;
}

export interface GenericTileType<TileVariation, AttributesType> {
  id: string;
  legacyID: string;
  organisationID: string;
  cloneSourceID: string;
  name: string;
  description: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  slug: string;
  type: TileVariation;
  isEnabled: boolean;
  isManagedByOrganisation: boolean;
  isAllowedExternally: boolean;
  isHiddenFromOrganisation: boolean;
  attributes: AttributesType;
  cookies?: TileCookie[];
  userData?: TileUserData;
  reactionSummary: { totalCount: number };
  userRoles?: TileUserRolesEntity[] | null;
  primarySource: 'hub' | 'learn';
  children: string[];

  /*** Learn only  ***/
  dueAt: string | null;

  // Item will become active and inactive at
  startAt: string | null;
  endAt: string | null;

  isMandatory: boolean;
  extendedDescription?: string;
  /*** End of learn only  ***/

  // Front end only
  createdByDetails: UserCoreType;

  // Create flow only
  audiences?: AudienceCoreType[];
  isFeatured?: boolean;
  isCarousel?: boolean;
}

export type EssentialFieldsTileType = GenericTileType<TileTypes, undefined>;

export type BasicTileType = GenericTileType<'XERO' | 'KUDOS', undefined>;

export type BookmarkVideoTileType = GenericTileType<'BOOKMARK' | 'VIDEO', BookmarkVideoTileAttributes>;

export type BoxsetSeasonTileType = GenericTileType<'BOXSET' | 'SEASON', BoxsetSeasonTileAttributes>;

export type ImageBankTileType = GenericTileType<'IMAGE_BANK', { thumbnail: TileThumbnail }>;

export type CollectionTileType = GenericTileType<'COLLECTION', CollectionTileAttributes>;

export type ArticleTileType = GenericTileType<'ARTICLE', ArticleTileAttributes>;

export type UploadTileType = GenericTileType<'UPLOAD', UploadTileAttributes>;

export type ScormTileType = GenericTileType<'SCORM', ScormTileAttributes>;

export type UploadedVideoTileType = GenericTileType<'UPLOADED_VIDEO', UploadedVideoTileAttributes>;

export type TileType =
  | BasicTileType
  | BookmarkVideoTileType
  | BoxsetSeasonTileType
  | CollectionTileType
  | ArticleTileType
  | UploadTileType
  | EssentialFieldsTileType
  | ImageBankTileType
  | ScormTileType
  | UploadedVideoTileType;

// Tiles that have the thumbnail property in attributes
export type ThumbnailTileType =
  | ArticleTileType
  | BookmarkVideoTileType
  | UploadTileType
  | BoxsetSeasonTileType
  | ScormTileType
  | UploadedVideoTileType
  | CollectionTileType;

export interface ThumbnailTileAttributes {
  heroImage: TileThumbnail;
  heroTextOptions: TileTextOptions;
  thumbnail: TileThumbnail;
  textOptions: TileTextOptions;
}

// Attributes associated with design controls in CreateTile
export interface BookmarkVideoTileAttributes extends ThumbnailTileAttributes {
  url: string;
  requiredTimeInSeconds: number;
}

export interface BoxsetSeasonTileAttributes extends ThumbnailTileAttributes {
  itemCount: number;
}

export interface CollectionTileAttributes {
  textOptions: TileTextOptions;
  externalShareURL: string;
  displayThumbnailChildren?: TileType[];
  childrenCount: number;
  hasExternalAccessToken: boolean;
  thumbnail: TileThumbnail;
  heroImage: TileThumbnail;

  // Only for POST/PATCH to trigger generation of external URL
  isSharedExternally?: boolean;
  externalAccessToken: string;
}

export interface ArticleTileAttributes extends ThumbnailTileAttributes {
  article: {
    title: string;
    description: string;
    content: string;
    isAuthorVisible: boolean;
    isCritical: boolean;
    isReadReceiptRequired: boolean;
    estimatedReadTime: number;
    thumbnail: TileThumbnail;
    textOptions: TileTextOptions;
  };
  requiredTimeInSeconds: number;
}

export type UploadFileTileTypes = Extract<TileTypes, 'UPLOAD' | 'SCORM' | 'UPLOADED_VIDEO'>;

export interface UploadType {
  // Only for POST/PATCH
  scanID: string;
  fileType: string;
  // Only for GET
  downloadURL: string;

  fileName: string;
  fileSizeInBytes: number;
  scannedAt: string;
}

export interface UploadTileAttributes extends ThumbnailTileAttributes {
  upload: UploadType;
  requiredTimeInSeconds: number;
}

export interface ScormTileAttributes extends UploadTileAttributes {
  launchLocation: string;
  version: '1.2' | '2004 3rd Edition';
}

interface VideoResolution {
  width: number;
  height: number;
}

export interface UploadedVideoTileAttributes extends UploadTileAttributes {
  totalFileSizeInBytes: 0;
  transcodedAt: string;
  uploadedBy: string;
  videoResolutions: VideoResolution[];
  path: string;
  fileName: string;

  // POST ONLY
  transcode: { jobID: string };
}

export interface TileThumbnail {
  colour: string;
  url: string;
  urlKey: string;
  croppedURL: string;
  croppedURLKey: string;
  filter: string;
  transforms: TileTransforms;
}
export interface TileTransforms {
  rotation: number;
  isFlippedVertical: boolean;
  isFlippedHorizontal: boolean;
  zoom: number;
}
export interface TileTextOptions {
  isVisible: boolean;
  isShadowVisible: boolean;
  isDarkMode: boolean;
}
export interface TileUserRolesEntity {
  name: string;
  description: string;
  rights?: string[] | null;
}

export type TileRoles = 'TILE_OWNER' | 'TILE_COLLABORATOR' | 'TILE_VIEWER';
type TileQueryCombo =
  | 'TILE_OWNER,TILE_COLLABORATOR'
  | 'TILE_OWNER,TILE_VIEWER'
  | 'TILE_COLLABORATOR,TILE_VIEWER'
  | 'TILE_OWNER,TILE_COLLABORATOR,TILE_VIEWER';

export type TileRolesQuery = TileRoles | TileQueryCombo;

// export interface CollectionUser extends Omit<UserCoreType, 'lastSeen' | 'organisationID' | 'userUUID'> {
//   id: string; // user UUID - could this not just be userUUID in the BE so we don't have to omit?
//   roles: string[]; // Array of TileRoles but only singular, not comma separated. BE only allow 1 array item for now.
// }

// Had to abandon above approach which uses the bulk of the UserCoreType because all of the attributes have 'id' and 'value' instead of 'ID' and 'Value'
// Need to follow up with BE on this one as we shouldn't need to create a whole new type for casing

interface CollectionUserAttributeType {
  id: string; // UUID
  value: string;
}

export interface CollectionUser {
  department: CollectionUserAttributeType;
  email: string;
  firstName: string;
  gender: CollectionUserAttributeType;
  geo: CollectionUserAttributeType;
  id: string; // user UUID
  lastName: string;
  location: CollectionUserAttributeType;
  role: CollectionUserAttributeType;
  roles: string[]; // Array of TileRoles but only singular, not comma separated. BE only allow 1 array item for now.
  startedAt: string; // ISO DATE
}

export type CollectionUsersResponse = APIListResponse<CollectionUser>;
