import styled from 'styled-components';
import { CSSProperties } from 'react';
import SvgLoader from 'components/Icons/SvgLoader';

interface LoaderProps {
  className?: string;
  label?: string;
  style?: CSSProperties;
  inline?: boolean;
}

const Loader = ({ className, label = '', style = {}, inline = false }: LoaderProps) => {
  return (
    <div className={className} style={inline ? style : undefined}>
      <figure style={style}>
        <div>
          <SvgLoader />
        </div>
      </figure>
      {label && <figcaption>{label}</figcaption>}
    </div>
  );
};

interface StyledLoaderProps {
  hasBg?: boolean;
  fullscreen?: boolean;
  inline?: boolean;
}

const StyledLoader = styled(Loader)<StyledLoaderProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  ${(props) =>
    props.fullscreen &&
    `
    top: 0;
    left: 0;
    position: absolute;
  `}
  ${(props) => props.inline && `position: relative;`}

  svg {
    width: 100% !important;
    height: 100% !important;
  }

  > figure {
    width: 70px;
    height: 70px;

    ${(props) =>
      props.hasBg &&
      `
      background-color: #fff;
      border-radius: 100px;
      box-shadow: 0px 15px 20px 5px rgba(0, 0, 0, 0.2);
    `}

    > div {
      width: 100%;
      height: 100%;
    }
  }

  > figcaption {
    text-align: center;
  }
`;

export default StyledLoader;
