export const redactedContentClassName = 'ph-no-capture';

// Event names should follow the [Space] - [Object] [Verb] format
export const eventNames = {
  core: {
    profilePictureChanged: 'Core - Profile Picture changed',
    currentApplicationType: 'Core - Current Application Type',
    currentLanguage: 'Core - Current Language',
    languageChanged: 'Core - Language changed',
    themeChanged: 'Core - Theme changed',
    loggedOut: 'Core - Logged out',
    notificationsTrayToggled: 'Core - Notifications Tray toggled',
    notificationClicked: 'Core - Notification clicked',
    bookmarksImported: 'Core - Bookmarks imported',
    // Tiles
    tileCreated: 'Core - Tile created',
    tileEdited: 'Core - Tile edited',
    tileCreateAbandoned: 'Core - Tile Create abandoned',
    tileEditAbandoned: 'Core - Tile Edit abandoned',
    // Collections
    collectionSharedInternally: 'Core - Collection shared internally',
    collectionSharedExternally: 'Core - Collection shared externally',
  },
  admin: {
    csvDownloaded: 'Admin - CSV downloaded',
    termsEdited: 'Admin - Terms and Conditions edited',
    // Users
    userCreated: 'Admin - User created',
    userEdited: 'Admin - User edited',
    // Org attributes
    orgAttributeCreated: 'Admin - Org Attribute created',
    orgAttributeEdited: 'Admin - Org Attribute edited',
    // Audiences
    audienceCreated: 'Admin - Audience created',
    audienceEdited: 'Admin - Audience edited',
    // Tickers
    tickerCreated: 'Admin - Ticker created',
    tickerEdited: 'Admin - Ticker edited',
  },
  journals: {
    commentAdded: 'Journals - Comment added',
    // Entries
    journalEntryCreated: 'Journals - Journal Entry created',
    journalEntryEdited: 'Journals - Journal Entry edited',
    journalEntryMarkedAsDraft: 'Journals - Journal Entry marked as draft',
    // Career Goals
    careerGoalCreated: 'Journals - Career Goal created',
    careerGoalEdited: 'Journals - Career Goal edited',
    // Personal Goals
    personalGoalCreated: 'Journals - Personal Goal created',
    personalGoalEdited: 'Journals - Personal Goal edited',
    // Business Goals
    businessGoalCreated: 'Journals - Business Goal created',
    businessGoalEdited: 'Journals - Business Goal edited',
    // Resources
    resourceCreated: 'Journals - Resource created',
    resourceEdited: 'Journals - Resource edited',
  },
  learn: {
    // Boxsets
    boxsetCreated: 'Learn - Boxset created',
    boxsetEdited: 'Learn - Boxset edited',
    boxsetCreateAbandoned: 'Learn - Boxset Create abandoned',
    boxsetEditAbandoned: 'Learn - Boxset Edit abandoned',
    boxsetSavedToMyHub: 'Learn - Boxset saved to My Hub',
    boxsetSavedToCollection: 'Learn - Boxset saved to Collection',
    // Items
    itemCreated: 'Learn - Item created',
    itemEdited: 'Learn - Item edited',
    itemCreateAbandoned: 'Learn - Item Create abandoned',
    itemEditAbandoned: 'Learn - Item Edit abandoned',
    itemSavedToMyHub: 'Learn - Item saved to My Hub',
    itemSavedToCollection: 'Learn - Item saved to Collection',
  },
  recognition: {
    recognitionGiven: 'Recognition - Recognition given',
  },
};
