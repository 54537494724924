import { CompositeAPIReturnType } from 'types';

const convertCompositeResponseToObject = (res: { responses: CompositeAPIReturnType[] }) => {
  const { responses } = res;

  if (!responses) return null;

  const compositeObject: { [key: string]: CompositeAPIReturnType } = {};
  responses.forEach((item: CompositeAPIReturnType) => {
    compositeObject[item.referenceID] = item;
  });

  return compositeObject;
};

export default convertCompositeResponseToObject;
