import { safeStorage, platform } from '@todesktop/client-core';
import { openDB } from 'idb';
import { hasIndexedDBSupport } from 'helpers/Utils';

const getDesktopAppDB = async () => {
  return openDB('desktopApp', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('userCredentials')) {
        db.createObjectStore('userCredentials', { keyPath: 'key' });
      }
    },
  });
};

export const encryptLoginForDesktopApp = async (email: string, password: string) => {
  try {
    const canEncrypt = await safeStorage.isEncryptionAvailable();

    if (!canEncrypt || !hasIndexedDBSupport()) return;

    const db = await getDesktopAppDB();
    const encryptedPassword = await safeStorage.encryptString(password);
    const transaction = db.transaction('userCredentials', 'readwrite');

    await Promise.all([
      transaction.store.put({
        key: 'email',
        value: email,
      }),
      transaction.store.put({
        key: 'password',
        value: encryptedPassword,
      }),
      transaction.done,
    ]);
  } catch (error) {
    console.error('Failed to save credentials', error);
  }
};

export const decryptLoginForDesktopApp = async (): Promise<{ email: string; password: string }> => {
  const emptyCredentials = { email: '', password: '' };

  try {
    const canEncrypt = await safeStorage.isEncryptionAvailable();

    if (!canEncrypt || !hasIndexedDBSupport()) return emptyCredentials;

    const db = await getDesktopAppDB();
    const savedEmail = await db.get('userCredentials', 'email');
    const savedPassword = await db.get('userCredentials', 'password');

    if (!savedEmail || !savedPassword) return emptyCredentials;

    const decryptedPassword = await safeStorage.decryptString(savedPassword.value);

    return {
      email: savedEmail.value || '',
      password: decryptedPassword || '',
    };
  } catch (error) {
    console.error('Failed to retrieve credentials', error);
    return emptyCredentials;
  }
};

export const removeSavedLoginDetails = async () => {
  if (!hasIndexedDBSupport()) return;

  try {
    const db = await getDesktopAppDB();
    const transaction = db.transaction('userCredentials', 'readwrite');

    await Promise.all([transaction.store.delete('email'), transaction.store.delete('password'), transaction.done]);
  } catch (error) {
    console.error('Failed to remove credentials', error);
  }
};

// Prevents huler.io links from opening within the desktop app window
export const wrapLinksForDesktopApp = (html: string) => {
  if (!platform.todesktop.isDesktopApp()) return html;

  const aTagPattern = /<a[^>]*href=['"]([^'"]*)['"][^>]*>(.*?)<\/a>/gi;

  const transformedHtml = html.replace(aTagPattern, (match, url, text) => {
    return `<button data-url="${url}">${text}</button>`;
  });

  return transformedHtml;
};
