import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from './AuthContext';
import { ConfigProvider } from './ConfigContext';

interface ContextWrapperProps {
  children: React.ReactNode;
}

function AppProviders({ children }: ContextWrapperProps) {
  return (
    <HelmetProvider>
      <ConfigProvider>
        <AuthProvider>{children}</AuthProvider>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default AppProviders;
