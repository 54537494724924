/* eslint-disable @typescript-eslint/no-explicit-any */
import { NIL as NIL_UUID } from 'uuid';
import { deleteData, getData, postData, patchData } from 'APIHandler';
import { TableFilter } from 'components/Table/TableHeaderActions/TableFilters/TableFilters';
import { cleanSearchParams } from 'helpers/Utils';
import { AdminUsersCreateFormData } from 'machines/admin/adminUsers/adminUsersCreate.machine';
import { PaginationQueryParams } from 'types';
import { SSOAuth } from 'types/organisations';
import { UserCapabilities, UserCombinedType } from 'types/users';

export interface GetUsersParams extends PaginationQueryParams {
  name?: string;
  email?: string;
  gender?: string;
  location?: string;
  geo?: string;
  privilegeLevel?: string;
  band?: string;
  role?: string;
  department?: string;
  isManager?: string;
  managerID?: string;
  passwordStatus?: string;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  fromStartedAt?: string | null;
  toStartedAt?: string | null;
  detail?: 'core' | 'core,extended' | 'core,extended,private';
}

interface GetUsersProps {
  organisationName: string;
  perPage?: number;
  offset?: number;
  filters?: TableFilter[];
  queryParams?: GetUsersParams;
  searchTerm?: string;
  filterByOrg?: string;
  coreOnly?: boolean;
}

export const getUsers = (settings: GetUsersProps) => {
  const {
    organisationName,
    perPage = '',
    offset = 0,
    filters = [],
    queryParams = {},
    searchTerm = '',
    filterByOrg = '',
    coreOnly = false,
  } = settings;

  let filtersString = '';
  filters.forEach((filter) => {
    if (filter.value && filter.value !== 'all') {
      filtersString += `&${filter.id}=${filter.value}`;
    }
  });

  if (queryParams) {
    const queryString = cleanSearchParams(new URLSearchParams(queryParams as Record<string, string>));
    filtersString += `&${queryString}`;
  }

  if (searchTerm) {
    filtersString += `&search=${searchTerm}`;
  }

  let perPageCount = '';
  if (perPage != '') {
    perPageCount = `&limit=${perPage}`;
  }

  const organisationId = filterByOrg ? filterByOrg : organisationName;

  // 'extended' detail query is only available to admins, so coreOnly should be true for Article Authors for example (as these are viewed by non admins).
  // Most use cases will be extended.
  const detail = coreOnly ? 'core' : 'core,extended';

  return getData('AppAPI', `users/${organisationId}/user/?detail=${detail}&offset=${offset}${filtersString}${perPageCount}`);
};

export const getOrganisationUsers = (organisationId: string, params: GetUsersParams) => {
  const queryString = cleanSearchParams(new URLSearchParams(params as Record<string, string>));
  return getData('AppAPI', `users/${organisationId}/user?${decodeURIComponent(queryString.toString())}`);
};

const getDeleteMethods = (users: string[], organisationId: string) => {
  return users.map((user, i) => {
    return {
      method: 'DELETE',
      url: `/users/${organisationId}/user/${user}`,
      referenceId: `deleteUser${i}`,
    };
  });
};

export const deleteUsers = (organisationId: string, users: string[]) => {
  if (users.length > 1) {
    // Bulk delete with composite
    return postData('NEW_CompositeUsersAPI', '', {
      compositeRequest: getDeleteMethods(users, organisationId),
    });
  }
  // delete single user
  return deleteData('NEW_UsersAPI', `${users[0]}`);
};

interface GetUserProps {
  organisationId: string;
  userId: string;
  adminOrgId?: string;
  detail?: 'core' | 'core,extended' | 'core,extended,private';
}

export const getUser = (settings: GetUserProps) => {
  const { organisationId, userId, adminOrgId = '', detail = 'core,extended' } = settings;
  return getData('AppAPI', `users/${adminOrgId || organisationId}/user/${userId}?detail=${detail}`);
};

// Function to remove empty strings as any attributes require a formatted UUID or for FE to send a 'nil' UUID
const checkEmptyStrings = (data: AdminUsersCreateFormData) => {
  const tempData = { ...data };

  Object.entries(tempData).forEach((entry) => {
    if (entry[1] === '' && entry[0] !== 'employeeID') {
      delete tempData[entry[0] as keyof AdminUsersCreateFormData];
    }
  });

  return tempData;
};

const formatUserAttributes = (data: UserCombinedType['attributes'], isEditing: boolean) => {
  const tempData = { ...data };

  Object.entries(tempData).forEach((entry) => {
    if (isEditing) {
      if (entry[1] === '' || entry[1] === NIL_UUID) {
        tempData[entry[0]] = null;
      }
    } else {
      if (entry[1] === '' || entry[1] === NIL_UUID || entry[1] === null) {
        delete tempData[entry[0]];
      }
    }
  });

  return tempData;
};

const formatUserForApi = (data: AdminUsersCreateFormData, isEditing: boolean, SSO?: SSOAuth[]) => {
  const filteredData = checkEmptyStrings(data);

  const formattedUserData = {
    ...filteredData,
    attributes: formatUserAttributes(data.attributes, isEditing),
  };

  if (isEditing) {
    return formattedUserData;
  } else {
    return {
      user: formattedUserData,
      // TO DO: We may need to toggle this with the frontend but for now, setting based on SSO org or not
      enablePasswordLogin: SSO === undefined,
      sendWelcomeEmail: SSO === undefined,
    };
  }
};

export const createUser = async (data: AdminUsersCreateFormData, orgId: string, SSO?: SSOAuth[]) => {
  const formattedData = formatUserForApi(data, false, SSO);
  return postData('AppAPI', `users/${orgId}/user`, formattedData);
};

export const updateUser = async (data: AdminUsersCreateFormData, userId: string, organisationId: string, adminOrgId?: string, SSO?: SSOAuth[]) => {
  const formattedData = formatUserForApi(data, true, SSO);
  return patchData('AppAPI', `users/${adminOrgId || organisationId}/user/${userId}`, formattedData);
};

export const resetPasswordOrInviteUsers = (users: UserCombinedType[], selectedIds: string[], organisationId: string, adminOrgId?: string) => {
  const selectedUsers = users.filter((user) => selectedIds.includes(user.id));
  const usersToInvite = selectedUsers.filter((user) => user.passwordStatus === 'ENABLED');
  const usersToReset = selectedUsers.filter((user) => user.passwordStatus === 'ACTIVE');

  return Promise.all([
    ...usersToInvite.map((user) => {
      return postData('AppAPI', `users/${adminOrgId || organisationId}/user/${user.id}/welcomeemail`), {};
    }),
    ...usersToReset.map((user) => {
      return postData('AppAPI', `users/${adminOrgId || organisationId}/user/${user.id}/resetpassword`), {};
    }),
  ]);
};

export const acceptOrganisationTerms = (userId: string, latestVersion: number) => {
  return patchData('NEW_UsersAPI', `${userId}`, { organisationTermsVersion: latestVersion });
};

interface GetUserCSVParams {
  limit?: number;
  privilegeLevel?: UserCapabilities;
  email?: string;
  csvFileName?: string;
}

export const getUsersCSV = async (organisationId: string, params?: GetUserCSVParams) => {
  const csvParams = { format: 'csv', ...params };
  const queryString = cleanSearchParams(new URLSearchParams(csvParams as any));

  return getData('AppAPI', `users/${organisationId}/user?${queryString}`);
};

interface UploadUserCSVProps {
  csv: string;
  dryRun: boolean;
  mode: 'replace-all' | 'update-only';
  // enablePasswordLogin also controls whether or not the welcome email is sent
  enablePasswordLogin?: boolean;
}

export const uploadUserCSV = async (settings: UploadUserCSVProps, orgPath?: string) => {
  const { csv: key, dryRun, mode, enablePasswordLogin } = settings;
  const apiOptions = { returnJSONError: true };
  const payload: UploadUserCSVProps = {
    csv: key,
    enablePasswordLogin: enablePasswordLogin ?? true,
    dryRun,
    mode,
  };

  const data = orgPath
    ? await postData('AppAPI', `users/${orgPath}/usercsv`, payload, apiOptions)
    : await postData('UserUploadAPI', '', payload, apiOptions);
  return data;
};
