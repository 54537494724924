import { createGlobalStyle } from 'styled-components';
import { darkVariables, lightVariables, sharedVariables } from 'theming/variables';
import { DesktopAppHeader } from 'components/DesktopApp/DesktopAppHeader';
import { scrollStyling } from '../mixins';
import animations from './animations';
import forms from './forms';
import reset from './reset';
import typography from './typography';

const GlobalStyles = createGlobalStyle`
	${reset}
	${animations}
	${typography}
	${forms}

	:root {
		${sharedVariables}

		${(props) => (props.theme.mode === 'dark' ? darkVariables : lightVariables)}
	}

	body,
	html {
		font-family: ${(props) => props.theme.fonts.primaryFont};
	}

	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
	}

	html {
		box-sizing: border-box;
		padding: 0;
		margin: 0;

		@media (max-width: ${(props) => props.theme.breakpoints.small}) {
			${scrollStyling};
			overflow-x: hidden !important;
			overflow-y: scroll;
		}
	}
	body {
		--desktopAppPadding: 0px;
		overscroll-behavior-y: none;
		&:has(${DesktopAppHeader}){
			--desktopAppPadding: 40px;
		}
		padding-top: var(--desktopAppPadding);
		@media (max-width: ${(props) => props.theme.breakpoints.small}) {
			height: auto;
		}
	}

	main {
		@media (max-width: ${(props) => props.theme.breakpoints.small}) {
			position: relative;
			height: auto;
			overflow: initial;
		}
	}

	button, input {
		font-family: ${(props) => props.theme.fonts.primaryFont};
	}

	hr {
		margin: 20px 0;
		border: none;
		-webkit-appearance: none;
		background-color: var(--border);
		height: 1px;
	}

	svg.stroke {
		stroke: currentColor;
		fill: none;
		stroke-width: 2px;
	}
`;

export default GlobalStyles;
