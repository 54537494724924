import { deleteData, getData, patchData, postData } from 'APIHandler';
import { cleanSearchParams } from 'helpers/Utils';
import { globalAudienceID } from 'helpers/constants';
import { PaginationQueryParams } from 'types';
import { AudienceType } from 'types/audiences';

interface GetAudiencesQueryParams extends PaginationQueryParams {
  isEnabled?: boolean;
  search?: string;
  sortBy?: 'name.asc' | 'createdAt.asc' | 'updatedAt.asc' | 'userCount.asc' | 'name.desc' | 'createdAt.desc' | 'updatedAt.desc' | 'userCount.desc';
  usersAttributes?: string;
}

const globalAudience = {
  id: globalAudienceID,
  name: 'Global Audience',
  description: '',
};

export const getAudiences = async (params?: GetAudiencesQueryParams) => {
  const queryObject = { limit: 999, offset: 0, ...params };
  const queryString = cleanSearchParams(new URLSearchParams(queryObject as unknown as Record<string, string>));

  const data = await getData('AudiencesAPI', `audience?${queryString}`);

  const additionalAudiences = queryObject?.limit === 999 ? [globalAudience] : [];

  return { items: [...data.items, ...additionalAudiences], itemCount: data.itemCount + additionalAudiences.length };
};

export const deleteAudience = (audienceUUID: string) => {
  return deleteData('AudiencesAPI', `audience/${audienceUUID}`);
};

export const createAudience = (audience: Partial<AudienceType>) => {
  return postData('AudiencesAPI', 'audience', audience);
};

export const editAudience = (audienceUUID: string, audience: Partial<AudienceType>) => {
  return patchData('AudiencesAPI', `audience/${audienceUUID}`, audience);
};

export const addOrEditAudience = (audienceUUID: string, audience: Partial<AudienceType>) => {
  if (audienceUUID) return editAudience(audienceUUID, audience);
  return createAudience(audience);
};

export const getAudience = (audienceUUID: string) => {
  return getData('AudiencesAPI', `audience/${audienceUUID}`);
};
